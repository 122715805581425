import React from "react"
import styles from "../recent-work/recent-work.module.scss"
import Container from "../../components/container/container"
import { Link } from "gatsby"

const Logos = props => {
  const data = props.data
  console.log(data)
  return (
    <div className={styles.recent}>
      <Container>
        <h2>Some of our logos</h2>
        <div className={styles.recent__work}>
          {data.map((item, key) => (
            <div className={styles.recent__work__item__logo} key={key}>
              <img src={item.Logo.publicURL} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Logos
