import React from "react"
import styles from "./packages.module.scss"
import Container from "../../components/container/container"
import Markdown from "react-markdown"

const Packages = props => {
  const data = props.data
  console.log(data)
  return (
    <div className={styles.packages}>
      <Container>
        <h2 data-sal="slide-right" data-sal-duration="1000">
          {data.BodyHeading}
        </h2>
        <div
          className={styles.packages__wrap}
          data-sal="fade"
          data-sal-duration="1000">
          {data.Packages.map((item, key) => (
            <div
              className={`${styles.packages__package} ${
                key === 2 ? styles.packages__package__active : ""
              }`}
              key={key}>
              <p className={styles.packages__package__title}>
                <strong>{item.Name}</strong>
              </p>
              <span className={styles.packages__package__price}>
                £{item.Price}
              </span>
              <p className={styles.packages__package__body}>
                <Markdown source={item.Body}></Markdown>
              </p>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Packages
